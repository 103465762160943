/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { connect } from 'mqtt/dist/mqtt'
import { guid, getStoreList } from '../api/api_v2'
import './order.css'
import { getkcard } from '../utils/common/common'
import { Button, notification } from 'antd';
import dingDong from "../assets/audio/dingdong.mp3";
import BaiDuYunApi from "../api/BaiDuYun";
import BaiduTts from "../utils/common/baidu_tts_cors";
import axios from "axios";
let BaiDuToken = '25.74184f3f7e4e6f292d283f26b3a25ee9.315360000.1996813854.282335-32059754';
let BaiDuAudio = null;
//这两个在百度智能云平台获取
//https://console.bce.baidu.com/ai/#/ai/speech/app/list
const API_Key = 'nAdpEhUekofkq98YdxP9GI9q';
const Secret_Key = 'y9NUSfkHlmbZAV0NoF1w1lF3dNmLKpr3';

/**
 * 播放叮咚声音
 */
const handleDingDong = async () => {
    if (!BaiDuToken) {
        const response = await axios.post(`https://aip.baidubce.com/oauth/2.0/token?client_id=${API_Key}&client_secret=${Secret_Key}&grant_type=client_credentials`);
        BaiDuToken = response.refresh_token;
    }
    const audio = new Audio(dingDong);
    audio.addEventListener('canplaythrough', event => {
        try {
            audio.play();
        } catch (error) {
            console.log('未获得系统授权，请主动点击页面任意位置');
        }
    });
}

/**
 * 请XX号顾客取餐
 * @param {*} number 
 */
const handleOrderCall = (number) => {
    BaiduTts({
        tex: `请${number}号顾客取餐`,
        tok: BaiDuToken,
        spd: 5,
        pit: 5,
        vol: 15,
        per: 4
    }, {
        volume: 0.3,
        autoDestory: true,
        timeout: 10000,
        hidden: false,
        onSuccess: (htmlAudioElement) => {
            BaiDuAudio = htmlAudioElement;
            BaiDuAudio.play();
        }
    });
    // const speechInstance = new window.SpeechSynthesisUtterance(`请${number}号顾客取餐`);
    // speechInstance.lang = 'zh-cn';
    // speechSynthesis.speak(speechInstance);
}


let lastClient = {};

const Order = () => {
    const [client, setClient] = useState(null);
    const [inProd, setInProduction] = useState([]);             //制作中
    const [prodCompleted, setProdCompleted] = useState([]);     //制作完成
    const [nowDateTime, setNowDateTime] = useState('');         //当前时间
    const [timerObject, setTimerObject] = useState(null);       //定时器对象

    const mqttConnect = (host, mqttOption) => {
        lastClient = connect(host, mqttOption);
        sessionStorage.setItem('lastClient', JSON.stringify(lastClient));
        setClient(lastClient);
    };

    const openNotification = () => {
        const key = `open${Date.now()}`;
        const btn = (
            <Button style={{ fontSize: 'calc(3vh)', width: 'calc(20vh)', height: 'calc(6vh)' }} type="primary" size="small" onClick={() => notification.close(key)}>
                确定
            </Button>
        );
        notification.open({
            style: {
                width: 'calc(50vh)',
                height: 'calc(18vh)'
            },
            message: <span style={{ fontSize: 'calc(3vh)' }}>系统将开起语音播报</span>,
            description: <span style={{ fontSize: 'calc(2vh)' }}>请点击确定</span>,
            btn,
            key,
            duration: null
        });
    };

    //页面第一次执行
    useEffect(() => {
        openNotification();
        document.title = '黑菠萝取餐提示系统'
        const onSetNowDateTime = () => {
            const date = new Date();
            let year = date.getFullYear();
            let month = chechTime(date.getMonth() + 1);
            let day = chechTime(date.getDate());
            let hour = chechTime(date.getHours());
            let minute = chechTime(date.getMinutes());
            let getSeconds = chechTime(date.getSeconds());
            let weekArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
            let weekIndex = date.getDay();
            let result = `${year}-${month}-${day} ${hour}:${minute}:${getSeconds} ${weekArr[weekIndex]}`;
            setNowDateTime(result);
        }

        const chechTime = (time) => {
            if (time < 10) {
                time = `0${time}`;
            }
            return time;
        }
        // 正式 emqx_u_block/emqx_p_admin84556576765490789

        // 测试 emqx_u_block/emqx_p_admin8456765490789
        var Mqpassword = "";
        //这是开发
        if (window.location.hostname === 'bpa.black-pa.com') {
            Mqpassword = "emqx_p_admin8765490789";
        } else if (window.location.hostname === 'helptest1.black-pa.com') {
            Mqpassword = "emqx_p_admin8456765490789";
        } else {
            Mqpassword = "emqx_p_admin84556576765490789";
        }
        mqttConnect(`wss://${window.location.hostname}:8085/mqtt`, {
            clean: true, // true: 清除会话, false: 保留会话
            connectTimeout: 10000, // 超时时间
            port: 8085,
            username: 'emqx_u_block', //连接用户名
            password: Mqpassword,//连接密码
            // 认证信息
            clientId: guid(),
        });
        if (timerObject) {
            clearInterval(timerObject);
        }
        let timer = setInterval(() => {
            onSetNowDateTime();
        }, 1000);
        setTimerObject(timer);
    }, [])

    // 监听MQTT是否变化
    useEffect(() => {
        //处理当前收到的消息
        const onHandReceivedMsg = (message) => {
            console.log('[收到消息]', message);
            const date = new Date();
            //制作中
            const tempInProd = inProd;
            const findInProdIndex = tempInProd.findIndex(item => item.SuborderId === message.SuborderId);
            //已完成
            const tempCompleted = prodCompleted;
            const findCompletedIndex = tempCompleted.findIndex(item => item.SuborderId === message.SuborderId);
            //CookingStatus: 等待制作：3， 制作中：4， 制作完成：5， 取餐完成：6， 
            switch (message.CookingStatus) {
                case 3:
                    if (findInProdIndex < 0) {
                        message.showStatus = '等待制作';
                        message.showTime = date.toLocaleString();
                        tempInProd.unshift(message);
                        setInProduction(tempInProd);
                    }
                    if (findInProdIndex >= 0) {
                        tempInProd[findInProdIndex].showStatus = '等待制作';
                        tempInProd[findInProdIndex].showTime = date.toLocaleString();
                        setInProduction(tempInProd);
                    }
                    break;
                case 4:
                    if (findInProdIndex < 0) {
                        message.showStatus = '制作中';
                        message.showTime = date.toLocaleString();
                        tempInProd.unshift(message);
                        setInProduction(tempInProd);
                    }
                    if (findInProdIndex >= 0) {
                        tempInProd[findInProdIndex].showStatus = '制作中';
                        tempInProd[findInProdIndex].showTime = date.toLocaleString();
                        setInProduction(tempInProd);
                    }
                    break;
                case 5:
                    if (findInProdIndex >= 0) {
                        tempInProd.splice(findInProdIndex, 1);
                        setInProduction(tempInProd);
                    }
                    if (findCompletedIndex >= 0) {
                        tempCompleted[findCompletedIndex].showStatus = '制作完成';
                        tempCompleted[findCompletedIndex].showTime = date.toLocaleString();
                        setProdCompleted(tempCompleted);
                    } else {
                        message.showStatus = '制作完成';
                        message.showTime = date.toLocaleString();
                        tempCompleted.unshift(message);
                    }
                    break;
                case 6:
                    if (findInProdIndex >= 0) {
                        tempInProd.splice(findInProdIndex, 1);
                        setInProduction(tempInProd);
                    }
                    if (findCompletedIndex >= 0) {
                        tempCompleted[findCompletedIndex].showStatus = '取餐完成';
                        tempCompleted[findCompletedIndex].showTime = date.toLocaleString();
                        setProdCompleted(tempCompleted);
                    } else {
                        message.showStatus = '取餐完成';
                        message.showTime = date.toLocaleString();
                        tempCompleted.unshift(message);
                    }
                    if (message?.SortNum) {
                        const orderNumber = message.SortNum.slice(0, message.SortNum.length - 1) || '未知';
                        handleDingDong();
                        setTimeout(() => {
                            handleOrderCall(orderNumber);
                        }, 800);

                        setTimeout(() => {
                            handleDingDong();
                            setTimeout(() => {
                                handleOrderCall(orderNumber);
                            }, 800);
                        }, 5000);
                    }
                    break;
                default:
                    if (findInProdIndex >= 0) {
                        tempInProd.splice(findInProdIndex, 1);
                        setInProduction(tempInProd);
                    }
                    if (findCompletedIndex >= 0) {
                        tempCompleted[findCompletedIndex].showStatus = '状态异常';
                        tempCompleted[findCompletedIndex].showTime = date.toLocaleString();
                        setProdCompleted(tempCompleted);
                    } else {
                        message.showStatus = '状态异常';
                        message.showTime = date.toLocaleString();
                        tempCompleted.unshift(message);
                    }
            }
        }
        //   订阅主题
        const mqttSub = (subscription) => {
            if (client) {
                const { topic, qos } = subscription;
                client.subscribe(topic, { qos }, (error) => {
                    if (error) {
                        console.log('Subscribe to topics error', error)
                        return
                    }
                });
            }
        };
        //   断开连接
        const mqttDisconnect = (clientLast) => {
            if (clientLast) {
                clientLast.end(() => {

                    sessionStorage.removeItem('lastClient');
                });
            }
        }
        if (client) {
            console.log('连接判断', client.connected)

            client.on('connect', (e) => {
                console.log('连接成功!', e);
                let autoKey = getkcard(window.location.search, 'autoKey');
                console.log('订阅消息!');
                mqttSub({ topic: `NT/${autoKey}`, qos: 2 });
            });

            client.on('error', (err) => {
                console.error('Connection error: ', err);
                client.end();
            });
            client.on('reconnect', (error) => {
                console.log('正在重连:', error)
            });
            client.on('message', (topic, message) => {
                const payload = { topic, message: message.toString() };
                // 收到信息后
                const receiveMsg = JSON.parse(payload.message);
                if (receiveMsg) {
                    onHandReceivedMsg(receiveMsg.Message);
                }
            });
        }
    }, [client]);

    return (
        <div>
            <div className='order-container'>
                <div className='order-header'>
                    <div className='company-logo'>
                        <img src="https://iotx-aep-img-prod-oxs.oss-cn-shanghai.aliyuncs.com/img/1641881043900_30564073.png" alt="菠萝饭圈.png" />
                    </div>
                    <div className='company-desc' onClick={() => {
                        const audio = new Audio(dingDong);
                        audio.addEventListener('canplaythrough', event => {
                            try {
                                audio.play();
                            } catch (error) {
                                console.log('未获得系统授权，请主动点击页面任意位置');
                            }
                        });
                        setTimeout(() => {
                            let speechInstance = new SpeechSynthesisUtterance('请520号顾客取餐');
                            speechSynthesis.speak(speechInstance);
                        }, 500);
                    }}>
                        菠萝饭圈叫号系统
                    </div>
                    <div className='now-date'>
                        {nowDateTime}
                    </div>
                    <div className='in-product-head'>
                        <div className='info-card'>
                            制作完成/取餐完成
                        </div>
                        {/* <div className='info-card-text'>制作完成/取餐完成</div> */}
                    </div>
                    <div className='comple-product-head'>
                        <div className='info-card'>
                            制作中/等待制作
                        </div>
                    </div>
                </div>
                <div className='order-main'>
                    {/* 制作完成 */}
                    <div className='order-list-item pro-completed-list'>
                        {
                            prodCompleted.length > 0 ? prodCompleted.map(item => {
                                return (
                                    <div className='product-detail animation-enter-2' key={item.SuborderId}>
                                        <div className='product-name'>
                                            <div>{item?.GoodName}</div>
                                            <div>取餐号:{item?.SortNum?.slice(0, item?.SortNum.length - 1)}</div>
                                        </div>
                                        <div className='product-status'>
                                            <span>{item?.showTime}&nbsp;</span>
                                            [&nbsp;{item?.showStatus}&nbsp;]
                                        </div>
                                    </div>
                                )
                            }) :

                                <div className='product-detail animation-enter-2' >
                                    <div className='product-name'>
                                        <div>监听中……</div>
                                        <div>暂无数据</div>
                                    </div>
                                </div>
                        }
                    </div>
                    {/* 制作中 */}
                    <div className='order-list-item pro-in-list'>
                        {
                            inProd.length > 0 ? inProd.map(item => {
                                return (
                                    <div className='product-detail animation-enter-1' key={item.SuborderId}>
                                        <div className='product-name'>
                                            <div>{item?.GoodName}</div>
                                            <div>取餐号:{item?.SortNum?.slice(0, item?.SortNum.length - 1)}</div>
                                        </div>
                                        <div className='product-status'>
                                            <span>{item?.showTime}&nbsp;</span>
                                            [&nbsp;{item?.showStatus}&nbsp;]
                                        </div>
                                    </div>
                                )
                            }) :
                                <div className='product-detail animation-enter-2' >
                                    <div className='product-name'>
                                        <div>监听中……</div>
                                        <div>暂无数据</div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Order