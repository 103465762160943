// 设备数据统计
/* eslint-disable */
import './statistics.css';
import * as echarts from 'echarts';
import React, { useEffect, useState } from 'react';
import { CalendarOutlined, SearchOutlined, DownOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import { DatePicker, Tabs, Cascader } from 'antd-mobile'
import { Button, message, Table, Dropdown, Menu, Space } from 'antd';
import { getkcard } from '../../utils/common/common';
import tasteCubeAPI from "../../api/tasteCube";

let id;
let orgName;

const materialsColums = [
    {
        title: '名称',
        dataIndex: 'name',
    },
    {
        title: '用量（g）',
        dataIndex: 'value',
        sortDirections: ['ascend', 'descend'],
        sorter: (a, b) => a.value - b.value
    }
]

const formulaColums = [
    {
        title: '名称',
        dataIndex: 'name',
    },
    {
        title: '次数',
        dataIndex: 'value',
        sortDirections: ['ascend', 'descend'],
        sorter: (a, b) => a.value - b.value
    }
]

const Statistics = () => {

    //时间选择器
    const [visibleStartTime, setVisibleStartTime] = useState(false);
    const [visibleEndTime, setVisibleEndTime] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [materials, setMaterials] = useState([]);                 //材料
    const [materialsPage, setMaterialsPage] = useState([]);         //材料分页数据
    const [currentPageIndex, setCurrentPageIndex] = useState(0);    //材料分页下标
    const [formula, setFormula] = useState([]);                     //配方
    const [formulaPage, setFormulaPage] = useState([]);             //配方分页数据
    const [formulaPageIndex, setFormulaPageIndex] = useState(0);    //配方分页下标
    const [tabIndex, setTabIndex] = useState('1');                  //当前tab
    const [showOrgList, setShowOrgList] = useState(false);          //是否展示组织列表
    const [orgList, setOrgList] = useState([]);                     //组织架构列表
    const [deviceList, setDeviceList] = useState([]);               //设备列表
    const [currentDevice, setCurrentDevice] = useState({});         //当前设备
    const [isStore, setIsStore] = useState(false);                  //当前组织是否为门店
    const [isDevice, setIsDevice] = useState(false);                //查询是否到设备

    const navigate = useNavigate();
    const location = useLocation();

    //eslint-disable-next-line
    useEffect(() => {
        document.title = '味魔方设备详情';
        id = getkcard(window.location.search, 'orgId');
        let isJoinStore = getkcard(window.location.search, 'isStore');
        orgName = decodeURIComponent(getkcard(window.location.search, 'orgName'));
        if (isJoinStore == 'true') {
            tasteCubeAPI.geDeviceList(id).then(response => {
                if (response.isSuccess) {
                    setDeviceList(response.data);
                }
            });
            setIsStore(true);
        }
        let date = new Date();
        let lastWeek = new Date(date.getTime() - 24 * 60 * 60 * 1000 * 7);
        let startT = `${lastWeek.toLocaleDateString()} 00:00:00`;
        let endT = `${date.toLocaleDateString()} 23:59:59`;
        setStartTime(startT);
        setEndTime(endT);
        //eslint-disable-next-line
        initDeviceData(startT, endT, 1);
        let tempOrgList = JSON.parse(sessionStorage.getItem('tree'));
        setOrgList(tempOrgList);
    }, [location]);

    //eslint-disable-next-line
    const initDeviceData = (startT, endT, currentTabIndex) => {
        let jsonData;
        if (startT && endT) {
            jsonData = {
                "beginTime": startT,
                "endTime": endT,
                "deviceId": id
            }
        } else {
            jsonData = {
                "beginTime": startTime,
                "endTime": endTime,
                "deviceId": id
            }
        }
        if (currentTabIndex == 1) {
            initMaterials(jsonData);
        } else if (currentTabIndex == 2) {
            initFormula(jsonData);
        }
    }


    useEffect(() => {
        //材料统计图
        let dom = document.getElementById('material-pie');
        if (dom) {
            const myPieChart = echarts.init(dom);
            const pieOptions = {
                title: {
                    text: '材料用量',
                    left: 10
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    right: '1%',
                    type: 'scroll',
                    orient: 'vertical'
                },
                series: [
                    {
                        name: '材料用量',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        center: ["30%", "45%"],
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '15',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: materialsPage[currentPageIndex]
                    }
                ]
            }
            myPieChart.setOption(pieOptions);
        }

    }, [materialsPage, currentPageIndex, currentDevice]);

    useEffect(() => {
        //配方统计
        let dom = document.getElementById('formula-pie');
        if (dom) {
            const formulaPieChart = echarts.init(dom);
            const pieOptions = {
                title: {
                    text: '配方用量',
                    left: 10
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: 'bottom',
                    type: 'scroll',
                },
                series: [
                    {
                        name: '配方用量',
                        type: 'pie',
                        radius: '60%',
                        data: formulaPage[formulaPageIndex]
                    }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
            formulaPieChart.setOption(pieOptions);
        }
    }, [formulaPage, formulaPageIndex, currentDevice]);


    //初始化材料用量
    const initMaterials = async (jsonData) => {
        //材料
        let response;
        if (isStore && isDevice) {
            response = await tasteCubeAPI.getDeviceRecipeBom(jsonData);
        } else {
            response = await tasteCubeAPI.getOrgRecipeBom(jsonData);
        }
        if (response.isSuccess) {
            let sortArr = response.data.sort((a, b) => b.value - a.value);
            setMaterials(sortArr);
            let tempPage = [];
            let pageCount = 10;
            let pageSize = Math.ceil(sortArr.length / pageCount);
            for (let i = 0; i < pageSize; i++) {
                let tempArr = sortArr.slice(i * pageCount, (i + 1) * pageCount);
                tempPage.push(tempArr);
            }
            tempPage.unshift(sortArr);
            setMaterialsPage(tempPage);
        } else {
            message.error(response.msg || '获取材料信息失败');
        }
    }

    const initFormula = async (jsonData) => {
        //配方
        let response;
        if (isStore && isDevice) {
            response = await tasteCubeAPI.getDeviceRecipe(jsonData);
        } else {
            response = await tasteCubeAPI.getOrgRecipe(jsonData);
        }
        if (response.isSuccess) {
            let sortArr = response.data.sort((a, b) => b.value - a.value);
            setFormula(sortArr);
            let tempPage = [];
            let pageCount = 10;
            let pageSize = Math.ceil(sortArr.length / pageCount);
            for (let i = 0; i < pageSize; i++) {
                let tempArr = sortArr.slice(i * pageCount, (i + 1) * pageCount);
                tempPage.push(tempArr);
            }
            tempPage.unshift(sortArr);
            setFormulaPage(tempPage);
        } else {
            message.error(response.msg || '获取配方信息失败');
        }
    }

    return (
        <div className="statistics-container">
            <div className="statistics-head" >
                <LeftOutlined style={{marginRight: '1rem'}} onClick={() => { navigate('/franchisee') }} />
                <div className='back-head-card' onClick={() => { setShowOrgList(true) }}>
                    {orgName}<DownOutlined style={{ marginLeft: '0.2rem' }} />
                </div>
                {
                    isStore ? <div className='device-change'>
                        <Dropdown overlay={
                            <Menu>
                                {
                                    deviceList.map(item => {
                                        return (
                                            <Menu.Item key={item.id} onClick={() => {
                                                setCurrentDevice(item);
                                                setIsDevice(true);
                                                navigate(`/statistics?orgId=${item.id}&orgName=${orgName}`);
                                            }}>
                                                {item.name}
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                        } trigger={['click']}>
                            <Space onClick={(e) => e.preventDefault()}>
                                {currentDevice.name ? currentDevice.name : '设备列表'}
                                <DownOutlined />
                            </Space>
                        </Dropdown>
                    </div> : null
                }
            </div>
            <div className='statistics-content'>
                {/* 材料/配方占比 */}
                <div className='material-card'>
                    <div className='start-end-time'>
                        <Button size='large' type="text" icon={<CalendarOutlined />} onClick={() => { setVisibleStartTime(true) }}>
                            {startTime.slice(0, 9) || '开始时间'}
                        </Button>
                        <Button size='large' type="text" icon={<CalendarOutlined />} onClick={() => { setVisibleEndTime(true) }}>
                            {endTime.slice(0, 9) || '结束时间'}
                        </Button>
                        <Button style={{borderRadius: '1rem'}} icon={<SearchOutlined />} onClick={() => {
                            if (startTime && endTime) {
                                initDeviceData(startTime, endTime, tabIndex);
                            } else {
                                message.info('请选择开始时间和结束时间');
                            }
                        }} >查询</Button>
                    </div>
                    <DatePicker
                        visible={visibleStartTime}
                        onClose={() => {
                            setVisibleStartTime(false)
                        }}
                        onConfirm={val => {
                            setStartTime(val.toLocaleDateString() + ' 00:00:00');
                        }}
                    />
                    <DatePicker
                        visible={visibleEndTime}
                        onClose={() => {
                            setVisibleEndTime(false)
                        }}
                        onConfirm={val => {
                            if (val.toLocaleDateString() + ' 23:59:59' < startTime) {
                                message.error('结束时间必须大于开始时间');
                            } else {
                                setEndTime(val.toLocaleDateString() + ' 23:59:59');
                            }
                        }}
                    />
                    <div className='tab-list-card'>
                        <div className={tabIndex == 1 ? 'tab-list-item tab-list-activity' : "tab-list-item "} onClick={() => {
                            setTabIndex('1');
                            initDeviceData(null, null, 1);
                            setFormulaPageIndex(0)
                        }}>
                            材料信息
                        </div>
                        <div className={tabIndex == 2 ? 'tab-list-item tab-list-activity' : "tab-list-item "} onClick={() => {
                            setTabIndex('2');
                            initDeviceData(null, null, 2);
                            setCurrentPageIndex(0)
                        }}>
                            配方信息
                        </div>
                    </div>
                    <Tabs activeKey={tabIndex} onChange={(currentInx) => {
                        setTabIndex(currentInx);
                    }}
                    >
                        <Tabs.Tab key='1'>
                            <div className='echart-pagenation'>
                                <Dropdown overlay={
                                    <Menu>
                                        {
                                            materialsPage.map((item, pageIndex) => {
                                                return (
                                                    <Menu.Item key={pageIndex} onClick={() => { setCurrentPageIndex(pageIndex) }}>
                                                        {pageIndex === 0 ? '全部' : `第${pageIndex}页`}
                                                    </Menu.Item>
                                                )
                                            })
                                        }
                                    </Menu>
                                } trigger={['click']}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            {currentPageIndex === 0 ? '全部' : `第${currentPageIndex}页`}
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                            <div className='material-pie' id="material-pie" style={{ width: '95vw', height: '45vh' }}></div>
                            <Table scroll={{ x: 'max-content', y: 400 }} rowKey={e => e.id} columns={materialsColums} dataSource={materials} showSorterTooltip={false} />
                        </Tabs.Tab>
                        <Tabs.Tab key='2'>
                            <div className='echart-pagenation'>
                                <Dropdown overlay={
                                    <Menu>
                                        {
                                            formulaPage.map((item, pageIndex) => {
                                                return (
                                                    <Menu.Item key={pageIndex} onClick={() => { setFormulaPageIndex(pageIndex) }}>
                                                        {pageIndex === 0 ? '全部' : `第${pageIndex}页`}
                                                    </Menu.Item>
                                                )
                                            })
                                        }
                                    </Menu>
                                } trigger={['click']}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            {formulaPageIndex === 0 ? '全部' : `第${formulaPageIndex}页`}
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                            <div className='formula-pie' id="formula-pie" style={{ width: '95vw', height: '60vh' }}></div>
                            <Table className='table-list' rowKey={e => e.name} columns={formulaColums} dataSource={formula} showSorterTooltip={false} />
                        </Tabs.Tab>
                    </Tabs>
                </div>
            </div>
            <div className='org-card-list'>
                <Cascader
                    options={orgList}
                    visible={showOrgList}
                    onClose={() => {
                        setShowOrgList(false)
                    }}
                    onConfirm={v => {
                        const originData = JSON.parse(sessionStorage.getItem('originData'));
                        const findOrg = originData.find(item => item.key === v[v.length - 1]);
                        setIsDevice(false);
                        setCurrentDevice({});
                        setDeviceList([]);
                        if (findOrg) {
                            if (findOrg.type == 2 || findOrg.type == 3) {
                                tasteCubeAPI.geDeviceList(findOrg.key).then(response => {
                                    if (response.isSuccess) {
                                        setDeviceList(response.data);
                                    }
                                })
                                setIsStore(true);
                            } else {
                                setIsStore(false);
                                setCurrentDevice({});
                                setDeviceList([]);
                            }
                            navigate(`/statistics?orgId=${findOrg.key}&orgName=${encodeURIComponent(findOrg.title)}`);
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default Statistics;